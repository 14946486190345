// 列表导出为excel
<template>

    <div class="comExportTableToExcel">
        <el-button type="primary"
                   v-loading="exportLoading"
                   @click="exportFile"
                   :size="buttonSize">导出</el-button>
    </div>

</template>

<script>
export default {
    name: "comExportTableToExcel",

    props: {
        // 按钮大小
        buttonSize: {
            type: String,
            default: "mini",
        },
        // 筛选条件
        searchForm: {
            type: Object,
            default: null,
        },
        // 导出类型，用于无法通过路由区分的组件
        type: {
            type: String,
            default: null,
        },
        // menuId
        menuId: {
            type: String,
            default: null,
        },
    },

    components: {},

    data() {
        return {
            exportLoading: false, // 导出中
            exportUrl: {
                合同收入: `/contract/contract/export/`, // 合同收入
                合同支出: `/contract/contract/export/`, // 合同支出
                其他收入: `/income/expenses-other/export/`, // 其他收入
                其他支出: `/income/expenses-other/export/`, // 其他支出
                付款管理: `/payment/management/export/`, // 付款管理
                费用列表: `/expenses/expenses/export/`, // 费用列表
                报销单: `/expenses/expense-account/export/`, // 报销单
                员工借款: `/advances/employee/export/`, // 员工借款
                出纳收款: `/cashier/payment/export/`, // 出纳收款
                出纳付款: `/cashier/payment/export/`, // 出纳付款
                收款历史: `/cashier/payment/record/export/`, // 收款历史
                付款历史: `/cashier/payment/record/export/`, // 付款历史
                开票申请: `/invoice/application/export/`, // 开票申请
                开票: `/invoice/application/export/`, // 开票
                销项发票: `/invoice/output-invoice/export/`, // 销项发票
                进项发票: `/invoice/output-invoice/export/`, // 进项发票
                资金调拨: `/ca/fund-transfer/export/`, // 资金调拨
                资金流水: `/capital/account/detail/flow/export/`, // 资金流水
                预收款: `/ca/imprest/export/`, // 预收款
                预付款: `/ca/imprest/export/`, // 预付款
                账户流水明细: `/capital/account/detail/export/`, // 账户流水明细
                收款管理: `/finance/gathering-management/export/`, // 收款管理
                收入合同期初: `/early-contract/export/`, // 收入合同期初
                支出合同期初: `/early-contract/export/`, // 支出合同期初
                其他收入期初: `/early-income-expenses-other/export/`, // 其他收入期初
                其他支出期初: `/early-income-expenses-other/export/`, // 其他支出期初
                税费汇总: `/tax/bill/exportSummary/`, // 税费汇总
                缴税费记录: `/tax/bill/export/`, // 缴税费记录
                发工资: `/cashier/payment/salary/export/`, // 发工资
                税费付款: `/cashier/payment/tax/export/`, // 税费付款
                股本管理: `/holder/stock-flow/export/`, // 股本管理
                支出合同结算: `/contract/settlement/export/`, // 支出合同结算
            },
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 导出
        exportFile() {
            let menuId = this.menuId || window.localStorage.getItem("tabId");
            this.exportLoading = true;
            let url = this.type
                ? `${this.exportUrl[this.type]}${menuId}`
                : `${this.exportUrl[this.routeName]}${menuId}`;
            this.commonJs
                .exportTableToExcel(
                    this.searchForm,
                    url,
                    this.type || this.routeName
                )
                .then(() => {
                    this.exportLoading = false;
                });
        },
    },

    computed: {
        // 页面名称
        routeName() {
            return this.$route.meta.title || "文件";
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.comExportTableToExcel {
    display: inline-block;
    margin-right: 15px;
}
</style>
