// 选择单据
// TODO 切换时列的顺序有问题
<template>
    <el-dialog :close-on-click-modal="false"
               :title="dialogTitle"
               :visible="true"
               width="1200px"
               :before-close="close">

        <div class="popItemBox">

            <el-tabs type="border-card"
                     v-model="currentTabs"
                     :before-leave="handleTip">
                <el-tab-pane :label="`${incomeOrPay === 'pay' ? '支出' : '收入'}合同`"
                             name="合同">
                </el-tab-pane>
                <el-tab-pane :label="`其他${incomeOrPay === 'pay' ? '支出' : '收入'}`"
                             name="其他">
                </el-tab-pane>
            </el-tabs>

            <el-form :model="form"
                     ref="form"
                     :rules="rules"
                     :inline="false"
                     size="normal">
                <div class="searchBar ofh">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             class="fll"
                             :inline="true">
                        <template v-if="currentTabs === '合同'">
                            <el-form-item label="合同名称"
                                          prop="name">
                                <el-input v-model="searchForm.name"
                                          placeholder="请输入合同名称"
                                          clearable />
                            </el-form-item>
                        </template>
                        <template v-if="currentTabs === '其他'">
                            <el-form-item :label="`${incomeOrPay === 'pay' ? '支出' : '收入'}编号`"
                                          prop="code">
                                <el-input v-model="searchForm.code"
                                          :placeholder="`请输入${incomeOrPay === 'pay' ? '支出' : '收入'}编号`"
                                          clearable />
                            </el-form-item>
                        </template>
                        <el-form-item>
                            <el-button type="primary"
                                       @click="getList()">搜索</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="resetSearchForm">清除</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="tableBox">
                    <el-table :data="tableData"
                              ref="tableBox"
                              height="300px"
                              stripe>
                        <el-table-column type="selection"
                                         width="55">
                        </el-table-column>
                        <template v-if="currentTabs === '合同'">
                            <el-table-column prop="incomeCode"
                                             label="合同编号"
                                             min-width="auto"
                                             width="160">
                            </el-table-column>
                            <el-table-column prop="name"
                                             label="合同名称"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                            <el-table-column prop="companyName"
                                             label="客户"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                            <el-table-column prop="actualAmount"
                                             label="实际收款金额（元）"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                            <el-table-column prop="canApplyAmount"
                                             label="未申请开票金额（元）"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                            <el-table-column prop="projectName"
                                             label="关联项目"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                            <el-table-column prop="deptName"
                                             label="部门"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                            <el-table-column prop="chargeUserName"
                                             label="负责人"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                        </template>
                        <template v-if="currentTabs === '其他'">
                            <el-table-column prop="incomeCode"
                                             :label="`${incomeOrPay === 'pay' ? '支出' : '收入'}编号`"
                                             min-width="auto"
                                             width="160">
                            </el-table-column>
                            <el-table-column prop="projectName"
                                             label="关联项目"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                            <el-table-column prop="companyName"
                                             label="客户"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                            <el-table-column prop="amount"
                                             label="金额（元）"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                            <el-table-column prop="canApplyAmount"
                                             label="未申请开票金额（元）"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                            <el-table-column prop="deptName"
                                             label="部门"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                            <el-table-column prop="chargeUserName"
                                             label="负责人"
                                             min-width="auto"
                                             width="auto">
                            </el-table-column>
                        </template>
                    </el-table>
                </div>
                <el-form-item class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="onSubmit">确认</el-button>
                </el-form-item>
            </el-form>

        </div>

    </el-dialog>
</template>
<script>
import API from "@/api/finance.js";
export default {
    name: "popConnectProject",
    components: {},
    props: {
        // 弹窗标题
        dialogTitle: {
            type: String,
            default: "关联项目",
        },
        // 收入还是支出
        incomeOrPay: {
            type: String,
            default: "income",
        },
        // 根据companyId查询单据
        companyId: {
            type: String,
        },
    },
    data() {
        return {
            currentTabs: "合同",
            form: {},
            rules: {},
            searchForm: {},
            isloading: false,
            rowData: null,
            tableData: [],
        };
    },
    mounted() {},
    methods: {
        /* 获取列表 */
        getList() {
            this.isloading = true;
            this.$refs.tableBox && this.$refs.tableBox.clearSelection();
            if (this.currentTabs === "其他") {
                // 其他收支
                let data = {
                    type:
                        this.incomeOrPay === "pay"
                            ? "PAY_OTHER"
                            : "INCOME_OTHER",
                    code: this.searchForm.code,
                    companyId: this.companyId || null,
                };
                API.getOtherBillList(data)
                    .then((res) => {
                        this.isloading = false;
                        this.$set(this, "tableData", res.content);
                        this.$refs.tableBox.doLayout();
                    })
                    .catch(() => {
                        this.isloading = false;
                    });
            } else {
                // 合同收支
                let data = {
                    type:
                        this.incomeOrPay === "pay"
                            ? "PAY_CONTRACT"
                            : "INCOME_CONTRACT",
                    name: this.searchForm.name,
                    companyId: this.companyId,
                };
                API.getContractBillList(data)
                    .then((res) => {
                        this.isloading = false;
                        this.$set(this, "tableData", res.content);
                        this.$refs.tableBox.doLayout();
                    })
                    .catch(() => {
                        this.isloading = false;
                    });
            }
        },

        //清空筛选条件
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 查询列表
                    this.getList();
                }
            });
        },

        // 提交表单
        onSubmit() {
            // 多选
            let rows = this.$refs.tableBox.store.states.selection;
            this.$emit("checkTicket", rows);
            this.close();
        },

        // 提示
        handleTip() {
            if (
                this.$refs.tableBox &&
                this.$refs.tableBox.store.states.selection.length
            ) {
                return new Promise((res, rej) => {
                    this.$confirm(
                        "你已有选中的数据，现在切换标签将会清空你选中的数据，是否继续?",
                        "提示",
                        {
                            confirmButtonText: "是",
                            cancelButtonText: "取消",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            res();
                        })
                        .catch(() => {
                            rej();
                        });
                });
            }
        },

        close() {
            this.$emit("close");
        },
    },
    computed: {},
    watch: {
        currentTabs: {
            immediate: true,
            handler: function () {
                this.getList();
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.el-form {
    padding: 0;
}
.searchBar {
    padding: 0 20px;
}
.tableBox {
    padding: 0 20px;
}
</style>