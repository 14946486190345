// 下拉选择供应商账户
<template>

    <div class="comSelectSupplierAccount">
        <el-select v-model="value"
                   filterable
                   :value-key="returnType === 'cardId' ? 'value' : 'name'"
                   :placeholder="placeholder"
                   @change="changeItem"
                   :loading="loading"
                   :disabled="disabled"
                   clearable>
            <el-option v-for="item in options"
                       :key="returnType === 'value' ? item.value : item.value.name"
                       :label="item.label"
                       :value="item.value">
            </el-option>
        </el-select>
    </div>

</template>

<script>
import API from "@/api/finance.js";
export default {
    name: "comSelectSupplierAccount",
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        // v-model 双向绑定：接受父组件的值
        value: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "请选择收款账户",
        },
        /**
         * 返回类型
         * 可选值: ['Object', 'value']
         */
        returnType: {
            type: String,
            default: "value",
        },

        // 选择的供应商id
        selectCusId: {
            type: String,
            default: null,
        },

        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    components: {},

    data() {
        return {
            loading: false,
            options: [],
            accountId: "", // 选中的账户id
            list: [],
        };
    },

    created() {},

    mounted() {
        // 获取账户列表
        if (this.selectCusId) {
            this.getAccountOpts();
        }
    },

    methods: {
        // 获取账户列表
        getAccountOpts() {
            this.loading = true;
            API.getCusAccountOpts(this.selectCusId)
                .then((res) => {
                    this.loading = false;
                    let data = res.content;
                    data = data.map((item) => {
                        let obj = { label: item.name, value: item.id };
                        if (this.returnType === "Object") {
                            obj.value = {
                                cardId: item.id, // 账户号
                                bankName: item.bankName, // 开户行
                                name: item.name, // 名称
                                cardOwner: item.cardOwner, // 开户人
                            };
                        } else {
                            obj.value = item.id;
                        }
                        return obj;
                    });
                    this.options = data;
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        // 清空
        cleanSelect() {
            this.accountId = "";
        },
    },

    computed: {},

    watch: {
        // 接收到父组件的value
        value: {
            deep: true,
            immediate: true,
            handler: function () {
                // 回显数据
                // this.showData();
                this.$emit("change", this.value);
            },
        },

        // 改变供应商下拉
        selectCusId: {
            immediate: true,
            handler: function () {
                if (this.selectCusId) {
                    this.getAccountOpts();
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
// .comSelectSupplierAccount{

// }
</style>
