// 销项发票详情
// popInputBillDetails
<template>

    <div class="popInputBillDetails"
         v-if="detailsInfo">
        <el-dialog title="销项发票详情"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="1000px"
                   :before-close="close">

            <div class="applyDetails">
                <div class="baseInfo">
                    <!-- <div class="icon">
                        <img src="../../../assets/imgs/dwlogo.png">
                    </div> -->
                    <div class="info">
                        <div class="company">
                            <!-- 发票类型 -->
                            <div class="name">增值税发票详情</div>
                            <div class="field">抬头名称：{{detailsInfo.invoiceTitle || "无"}}
                                &nbsp;&nbsp;|&nbsp;&nbsp;税号：{{detailsInfo.taxId || "无"}}
                                &nbsp;&nbsp;|&nbsp;&nbsp;地址：{{detailsInfo.addr || "无"}}
                                &nbsp;&nbsp;|&nbsp;&nbsp;电话：{{detailsInfo.phone || "无"}}
                                &nbsp;&nbsp;|&nbsp;&nbsp;开户行：{{detailsInfo.accountBank || "无"}}
                                &nbsp;&nbsp;|&nbsp;&nbsp;账号：{{detailsInfo.account || "无"}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="popItemBox">
                    <el-tabs type="border-card">
                        <el-tab-pane label="明细">
                            <div class="tableBox">
                                <el-table :data="detailsInfo.invoiceDetailSaveActionList"
                                          border
                                          stripe>
                                    <el-table-column prop="content"
                                                     label="内容"
                                                     align="left"
                                                     min-width="auto"
                                                     width="220">
                                    </el-table-column>
                                    <el-table-column prop="amount"
                                                     label="金额（元）"
                                                     align="left"
                                                     min-width="auto">
                                    </el-table-column>
                                    <el-table-column prop="taxRate"
                                                     label="税率（%）"
                                                     align="left"
                                                     min-width="auto">
                                    </el-table-column>
                                    <el-table-column prop="taxAmount"
                                                     label="税额（元）"
                                                     align="left"
                                                     min-width="auto">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-tab-pane>
                    </el-tabs>

                    <div class="popItemBox ofh">
                        <div class="countItem">
                            <div class="count">{{detailsInfo.totalAmount}}</div>
                            <div class="unit">价税合计（元）</div>
                        </div>
                    </div>
                </div>

                <div class="popItemBox">
                    <el-tabs type="border-card">
                        <el-tab-pane label="详细信息">
                            <p><span class="label">关联开票申请单：</span><span class="value">{{detailsInfo.busCode || "无"}}</span></p>
                            <p><span class="label">销方公司：</span><span class="value">{{detailsInfo.sellerCompanyName || "无"}}</span></p>
                            <p><span class="label">税号：</span><span class="value">{{detailsInfo.taxId || "无"}}</span></p>
                            <p><span class="label">备注：</span><span class="value">{{detailsInfo.remark || "无"}}</span></p>
                        </el-tab-pane>
                        <el-tab-pane label="附件">
                            <com-file-upload-list fileBoxHeight="250px"
                                                  :showUpBtn="false"
                                                  :uploadData="{resourceId: id, resourceType: 'addInvoiceInput'}"
                                                  :neddLoadFile="true"
                                                  :justShowFistOne="false"
                                                  :isNeedPopTemDownload="false"
                                                  :showDownloadBtn="true"
                                                  :showDelBtn="true"></com-file-upload-list>
                        </el-tab-pane>
                    </el-tabs>
                </div>

                <div class="popItemBox">
                    <el-tabs type="border-card">
                        <el-tab-pane label="相关业务">
                            <div class="tableBox">
                                <com-dynamic-table ref="相关业务"
                                                   :tableData="detailsInfo.invoiceReceiptsSaveActionList"
                                                   type="销项发票相关业务"></com-dynamic-table>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>

            </div>
        </el-dialog>

    </div>
</template>

<script>
import ComDynamicTable from "../../incomePayManage/components/comDynamicTable";
import ComFileUploadList from "../../../components/upload/comFileUploadList";
import { Loading } from "element-ui";
import financeAPI from "@/api/finance.js";
export default {
    name: "popInputBillDetails",

    props: {
        // 查询详情的id
        id: {
            type: String,
            default: null,
        },
        /**
         * 付款类型
         */
        type: {
            type: String,
        },

        // 是支出还是收款
        incomeOrPay: {
            type: String,
            default: "income",
        },
    },

    components: {
        ComDynamicTable,
        ComFileUploadList,
    },

    data() {
        return {
            dialogVisible: true,
            detailsInfo: {}, // 详情
        };
    },

    created() {},

    mounted() {
        // 初始化数据
        this.init();
    },

    methods: {
        // 初始化数据
        init() {
            // 获取详情
            this.getDetails();
        },

        // 获取详情
        getDetails() {
            let loading = Loading.service({
                target: document.querySelector(".applyDetails"),
            });
            financeAPI
                .getOutBill(this.id)
                .then((res) => {
                    loading.close();
                    this.detailsInfo = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 更新数据并关闭弹窗
        update() {
            this.$emit("update");
            this.close();
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
    display: flex;
    padding: 0;
    position: relative;
    .applyDetails {
        flex: auto;
        padding: 30px 40px 30px 20px;
        position: relative;
        padding-bottom: 72px;
    }
    .applyInfo {
        width: 344px;
        padding: 30px 20px;
        background: #fbfdfe;
        box-shadow: -1px 0px 0px 0px #ebeef5;
        border-radius: 0px 0px 10px 0px;
        position: relative;
        padding-bottom: 72px;
        .title {
            height: 20px;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 30px;
        }
    }
}
.baseInfo {
    overflow: hidden;
    margin-bottom: 30px;
    position: relative;
    .icon {
        width: 64px;
        height: 64px;
        background: #ffffff;
        box-shadow: 0px 2px 3px 0px rgba(114, 123, 129, 0.28);
        border-radius: 50%;
        float: left;
        margin-right: 22px;
        position: relative;
        img {
            width: 46px;
            height: 46px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    .info {
        float: left;
        width: calc(100% - 64px - 22px);
        .company {
            .name {
                line-height: 19px;
                font-size: 20px;
                color: #333333;
                margin-bottom: 17px;
                .tip {
                    padding: 0 9px;
                    background: #eef8e7;
                    display: inline-block;
                    background: rgba(253, 86, 86, 0);
                    border: 1px solid #5cbd18;
                    border-radius: 2px;
                    font-size: 12px;
                    color: #5cbd18;
                    line-height: 20px;
                    margin-left: 15px;
                }
            }
            .code {
                font-size: 14px;
                color: #999999;
                margin-bottom: 10px;
            }
            .field {
                width: auto;
                min-height: 15px;
                font-size: 14px;
                color: #666666;
                vertical-align: middle;
            }
        }
    }
    .controlHisBtn {
        position: absolute;
        right: 0;
        top: 0;
        width: 104px;
        height: 40px;
        background: #0091fb;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
    }
}
.countItem {
    width: 33.333%;
    height: 100px;
    float: left;
    text-align: center;
    position: relative;
    .tip {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        background: #e5f4ff;
        border: 1px solid #0091fb;
        border-radius: 2px;
        padding: 0 8px;
        font-size: 12px;
        font-weight: 400;
        color: #0091fb;
        position: absolute;
        left: 0;
        top: 0;
    }
    .count {
        font-size: 36px;
        font-family: DIN;
        font-weight: bold;
        color: #7e85ee;
        padding-top: 12px;
        padding-bottom: 10px;
    }
    .count.green {
        color: #5cbd18;
    }
    .count.blue {
        color: #14c2c3;
    }
    .unit {
        font-size: 16px;
        color: #333333;
    }
    .line {
        width: 2px;
        height: 60px;
        background: #f2f5f8;
        position: absolute;
        right: 0;
        top: 20px;
    }
}
.footer {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 25px;
    text-align: right;
    .el-button {
        min-width: 100px;
        width: 100px;
        height: 32px;
        border-radius: 4px;
        font-size: 14px;
        color: #ffffff;
        margin: 0 10px;
    }
}
</style>
