// 进项发票 || 销项发票
<template>

    <div class="inputBill baseBg">

        <div class="listBox mainWhiteBg">
            <div class="tableBox">
                <div class="greySearchBar">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             :rules="searchFormRules"
                             :inline="true">

                        <el-form-item label="销方公司"
                                      prop="sellerCompanyId">
                            <cust-sup-choose placeholderStr="请选择销方公司"
                                             :showSeeProvider="false"
                                             :disable="true"
                                             :shouldClean="true"
                                             :isScorll="true"
                                             @handClear="handClear"
                                             :cleanProvider="cleanProvider"
                                             @treeSelectNodeClick="customerClick"></cust-sup-choose>
                        </el-form-item>

                        <el-form-item label="开票类型"
                                      prop="type">
                            <el-select v-model="searchForm.type"
                                       placeholder="请选择开票类型"
                                       clearable>
                                <el-option v-for="item in billTypeOpts"
                                           :key="item.code"
                                           :label="item.label"
                                           :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="日期"
                                      prop="timeList">
                            <el-date-picker v-model="searchForm.timeList"
                                            type="daterange"
                                            value-format="yyyy-MM-dd"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>

                        <el-button type="primary"
                                   @click="getList">搜索</el-button>
                        <el-button @click="resetSearchForm">清除</el-button>

                        <el-button type="success"
                                   class="flr"
                                   v-if="routeName === '进项发票'"
                                   @click="openpopEditInputBill()">
                            新增进项发票
                        </el-button>
                    </el-form>
                </div>

                <!-- 控制条 -->
                <div class="greySearchBar control">
                    <com-export-table-to-excel :searchForm="Object.assign({}, this.searchForm, {
                            invoiceInOutType:
                                this.routeName === '进项发票'
                                    ? 'INVOICE_INPUT'
                                    : 'INVOICE_OUTPUT',
                        })"></com-export-table-to-excel>
                </div>

                <div class="tableBox">
                    <el-table :data="tableData.data"
                              border
                              :height="tableHeight"
                              tooltip-effect="dark"
                              stripe>
                        <el-table-column label="序号"
                                         type="index"
                                         align="center"
                                         width="60">
                        </el-table-column>
                        <el-table-column label="开票日期"
                                         prop="occurDateStr"
                                         align="center"
                                         width="128">
                        </el-table-column>
                        <el-table-column prop="invoiceNumber"
                                         label="发票号码"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="type.text"
                                         label="发票类型"
                                         align="left"
                                         min-width="auto"
                                         width="125">
                        </el-table-column>
                        <el-table-column prop="sellerCompanyName"
                                         label="销方公司"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="companyName"
                                         label="客户/供应商"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="content"
                                         label="开票内容"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="taxRate"
                                         label="税率（%）"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="amount"
                                         label="金额（元）"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="taxAmount"
                                         label="税额（元）"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="totalAmount"
                                         label="价税合计（元）"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="remark"
                                         label="备注"
                                         align="left"
                                         min-width="auto"
                                         width="220">
                            <template slot-scope="scope">
                                <el-tooltip class="item"
                                            effect="dark"
                                            :content="scope.row.remark">
                                    <p class="ellipsis"
                                       v-if="scope.row.remark">{{scope.row.remark}}</p>
                                    <span v-else>暂无</span>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status.text"
                                         v-if="routeName === '销项发票'"
                                         label="状态"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="id"
                                         label="操作"
                                         align="left"
                                         fixed="right"
                                         min-width="120">
                            <template slot-scope="scope">
                                <template v-if="routeName === '进项发票'">
                                    <el-button type="primary"
                                               size="mini"
                                               @click="openpopEditInputBill(scope.row)">编辑</el-button>
                                    <el-popconfirm confirm-button-text="删除"
                                                   cancel-button-text="取消"
                                                   icon="el-icon-info"
                                                   icon-color="red"
                                                   @confirm="del(scope.row.id)"
                                                   title="是否删除此条数据？">
                                        <el-button type="danger"
                                                   slot="reference"
                                                   size="mini">删除</el-button>
                                    </el-popconfirm>
                                </template>
                                <template v-else>
                                    <el-button type="primary"
                                               size="mini"
                                               @click="openpopInputBillDetails(scope.row)">查看</el-button>
                                    <template v-if="scope.row.status && scope.row.status.text === '作废'">
                                        <el-popconfirm confirm-button-text="删除"
                                                       cancel-button-text="取消"
                                                       icon="el-icon-info"
                                                       icon-color="red"
                                                       @confirm="del(scope.row.id)"
                                                       title="是否删除此条数据？">
                                            <el-button type="danger"
                                                       slot="reference"
                                                       size="mini">删除</el-button>
                                        </el-popconfirm>
                                    </template>
                                    <template v-if="scope.row.status && scope.row.status.text !== '作废'">
                                        <el-button type="primary"
                                                   size="mini"
                                                   @click="openpopEditInputBill(scope.row)">编辑</el-button>
                                        <el-popconfirm confirm-button-text="作废"
                                                       cancel-button-text="取消"
                                                       icon="el-icon-info"
                                                       icon-color="red"
                                                       v-if="routeName === '销项发票'"
                                                       @confirm="invalidOutputBill(scope.row.id)"
                                                       title="是否作废此条数据？">
                                            <el-button type="warning"
                                                       slot="reference"
                                                       size="mini">作废</el-button>
                                        </el-popconfirm>
                                    </template>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="pageFooter">
                        <!-- 金额统计 -->
                        <amount-statistical :type="routeName"
                                            :totalData="statistical"></amount-statistical>
                        <!-- 分页 -->
                        <Pagination :page="searchForm.page"
                                    :limit="searchForm.size"
                                    :total="pageTotal"
                                    @pagination="paginationChange" />
                    </div>

                </div>
            </div>
        </div>

        <!-- 编辑进项发票 -->
        <pop-edit-input-bill v-if="popEditInputBillVisible"
                             :id="detailsRow ? detailsRow.id : null"
                             @update="getList()"
                             @close="closepopEditInputBill"></pop-edit-input-bill>
        <!-- 销项发票详情 -->
        <pop-input-bill-details v-if="popInputBillDetailsVisible"
                                :id="detailsRow.id"
                                @update="getList()"
                                @close="closepopInputBillDetails"></pop-input-bill-details>
    </div>

</template>

<script>
import ComExportTableToExcel from "../../components/export/comExportTableToExcel";
import AmountStatistical from "../../components/amountStatistical/amountStatistical";
import CustSupChoose from "../../components/selectTree/custSupChoose";
import PopInputBillDetails from "./components/popInputBillDetails";
import PopEditInputBill from "./components/popEditInputBill";
import { Loading } from "element-ui";
import API from "@/api/finance.js";
import Pagination from "@/components/Pagination"; // 分页
export default {
    name: "inputBill",

    props: {},

    components: {
        ComExportTableToExcel,
        AmountStatistical,
        CustSupChoose,
        PopInputBillDetails,
        PopEditInputBill,
        Pagination,
    },

    data() {
        return {
            searchForm: {
                queryFlag: "1", // tabs 1=待开票,2=已完成
                timeList: [], // 日期
                page: 1,
                size: 20,
            },
            searchFormRules: {},
            pageTotal: 0,

            tableData: {
                data: [],
            },
            // tabs对应文字
            tabsName: ["待开票", "已完成"],
            counts: {
                1: 0, // 待开票 数量
                2: 0, // 已完成 数量
            },
            // 发票类型
            billTypeOpts: this.commonJs.getSelectByType(
                "InvoiceType",
                "billTypeOpts",
                this,
                true
            ),
            needClean: true, // 清除销方公司
            popEditInputBillVisible: false, // 编辑开票弹窗显示
            popInputBillDetailsVisible: false, // 销项发票详情弹窗显示
            cleanProvider: false, //清空客户供应商
            detailsRow: null, // 编辑中的行信息
            statistical: null, // 统计信息
        };
    },

    created() {},

    mounted() {
        // 获取列表
        this.getList();
    },

    methods: {
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            let data = Object.assign({}, this.searchForm, {
                invoiceInOutType:
                    this.routeName === "进项发票"
                        ? "INVOICE_INPUT"
                        : "INVOICE_OUTPUT",
            });
            API.getInputBillList(data)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                    this.statistical = res.content.statistical;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 删除发票
        del(id) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API[
                this.$route.meta.title === "进项发票"
                    ? "delInputBill"
                    : "delOutputBill"
            ](id)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    this.getList();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 作废发票
        invalidOutputBill(id) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.invalidOutputBill(id)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    this.getList();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            this.getList();
        },
        // 清空表单
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 客户的清空
                    this.handClear();
                    this.needClean = Math.random();
                    // 查询列表
                    this.getList();
                }
            });
        },
        // 客户的选择
        customerClick(node) {
            this.searchForm.sellerCompanyId = node.id;
        },
        // 客户的清空
        handClear() {
            this.searchForm.sellerCompanyId = "";
            this.cleanProvider = Math.random();
        },
        // 编辑开票 打开弹框
        openpopEditInputBill(row) {
            this.detailsRow = row || null;
            this.popEditInputBillVisible = true;
        },
        // 编辑开票 关闭弹框
        closepopEditInputBill() {
            this.detailsRow = null;
            this.popEditInputBillVisible = false;
        },
        // 销项发票详情 打开弹框
        openpopInputBillDetails(row) {
            this.detailsRow = row || null;
            this.popInputBillDetailsVisible = true;
        },
        // 销项发票详情 关闭弹框
        closepopInputBillDetails() {
            this.detailsRow = null;
            this.popInputBillDetailsVisible = false;
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 305 });
        },
        /**
         * 根据路由判断页面
         * inputBill == 进项发票, outputBill == 销项发票
         */
        routeName() {
            let routeInfo = {
                inputBill: "进项发票",
                outputBill: "销项发票",
            };
            return routeInfo[this.$route.name] || null;
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
// .inputBill{

// }
</style>
