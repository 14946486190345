// 金额统计
<template>

    <div class="amountStatistical">
        <!-- 付款管理 -->
        <span class="statistical"
              v-for="(item,idx) in showMap[type]"
              :key="idx">
            <span class="key">{{item.key}}：</span><span class="value">{{commonJs.toThousands(totalData[item.value])}}</span>
        </span>
    </div>

</template>

<script>
export default {
    name: "amountStatistical",

    props: {
        // 模块类型，仅用于前端区分展示对应字段
        type: {
            type: String,
            default: null,
        },
        // 金额
        totalData: {
            type: Object,
            default: null,
        },
    },

    components: {},

    data() {
        return {
            // 显示的文字和对应后端字段映射
            showMap: {
                付款管理: [
                    { key: "申请金额", value: "allAmount" },
                    { key: "余额合计", value: "allBalanceAmount" },
                ],
                费用列表: [{ key: "金额合计", value: "allAmount" }],
                报销单: [
                    { key: "报销金额合计", value: "allAmount" },
                    { key: "待付金额合计", value: "allBalanceAmount" },
                ],
                员工借支: [
                    { key: "借款金额合计", value: "allAmount" },
                    { key: "借款余额合计", value: "allBalanceAmount" },
                ],
                开票申请: [{ key: "合计金额", value: "allAmount" }],
                开票: [
                    { key: "申请金额合计", value: "allAmount" },
                    { key: "余额合计", value: "allBalanceAmount" },
                ],
                销项发票: [
                    { key: "税额合计", value: "allTaxAmount" },
                    { key: "价税合计", value: "allAmount" },
                ],
                进项发票: [
                    { key: "税额合计", value: "allTaxAmount" },
                    { key: "价税合计", value: "allAmount" },
                ],
                出纳收款: [
                    { key: "应收金额合计", value: "allAmount" },
                    { key: "已收金额合计", value: "allPaidAmount" },
                    { key: "应收余额合计", value: "allBalanceAmount" },
                    { key: "金额合计", value: "allAmount" },
                ],
                收款历史: [{ key: "金额合计", value: "allAmount" }],
                出纳付款: [
                    { key: "应付金额合计", value: "allAmount" },
                    { key: "已付金额合计", value: "allPaidAmount" },
                    { key: "应付余额合计", value: "allBalanceAmount" },
                    { key: "金额合计", value: "allAmount" },
                ],
                付款历史: [{ key: "金额合计", value: "allAmount" }],
                资金调拨: [{ key: "金额合计", value: "allAmount" }],
                资金流水: [
                    { key: "流入合计", value: "allInAmount" },
                    { key: "流出合计", value: "allOutAmount" },
                    { key: "差额", value: "subtractAmount" },
                ],
                预收款: [
                    { key: "金额合计", value: "allAmount" },
                    { key: "预收金额合计", value: "unFinishAmount" },
                    { key: "预收余额合计", value: "allBalanceAmount" },
                ],
                预付款: [
                    { key: "金额合计", value: "allAmount" },
                    { key: "预付金额合计", value: "unFinishAmount" },
                    { key: "预付余额合计", value: "allBalanceAmount" },
                ],
                资金管理明细: [
                    { key: "流出合计", value: "allOutAmount" },
                    { key: "流入合计", value: "allInAmount" },
                    { key: "账户余额", value: "allBalanceAmount" },
                ],
                收款管理: [
                    { key: "金额合计", value: "allAmount" },
                    { key: "已收款金额合计", value: "allPaidAmount" },
                    { key: "待收余额合计", value: "allBalanceAmount" },
                ],
            },
        };
    },

    created() {},

    mounted() {},

    methods: {},

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.amountStatistical {
    padding: 30px 0 0;
    .statistical {
        margin-right: 15px;
    }
}
</style>
