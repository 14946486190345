// import request from '@/utils/request'
import {
    get,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const baseManageApi = {
    // 软件信息列表
    getSoftList(params) {
        return postJson("/baseinfo/system-info/table", params);
    },
    // 新建软件系统
    saveSoft(params) {
        return postJson("/baseinfo/system-info/save", params);
    },
    // 编辑软件系统
    updateSoft(params) {
        return postJson("/baseinfo/system-info/update", params);
    },
    // 删除任务
    delSoft(params) {
        return get(`/baseinfo/system-info/delete`, params);
    },
    // 软件版本信息列表
    getSoftVersionList(params) {
        return postJson("/baseinfo/system-version-info/table", params);
    },
    // 新建软件版本系统
    saveSoftVersion(params) {
        return postJson("/baseinfo/system-version-info/save", params);
    },
    // 编辑软件版本系统
    updateSoftVersion(params) {
        return postJson("/baseinfo/system-version-info/update", params);
    },
    // 删除软件版本
    delSoftVersion(params) {
        return get(`/baseinfo/system-version-info/delete`, params);
    },

    // 设备类型列表
    getDeviceTypeList(params) {
        return postJson("/emp/type-manager/table", params);
    },
    // 设备类型新增/修改
    saveDeviceType(params) {
        return postJson("/emp/type-manager/save", params);
    },
    //获取客户/供应商信息树形结构数据-HSP
    getCustomerCompanyTree(parame) {
        return get(`/baseinfo/company/tree`, parame);
    },
    //根据公司id获取该公司下联系人列表信息-HSP
    getCompanyPerson(parame) {
        return get(`/baseinfo/company-link-info/findByCompanyId`, parame);
    },

    //根据ID查询所有相应设备型号的相应扩展字段
    getEmpTypeId(id) {
        return get(`/emp/type-field/findByTypeId/${id}`);
    },

    //删除设备类型
    deleteEmpType(id) {
        return get(`/emp/type-manager/deleteById/${id}`);
    },

    // 根据条件获取设备基础信息(采购单选择设备)
    getEquipmentModelList(params) {
        return postJson("/emp/type-model/list", params);
    },

    // 新增规格型号
    saveEquipmentMode(params) {
        return postJson("/emp/type-model/save", params);
    },

    // 删除规格型号
    deleteEquipmentMode(id) {
        return get(`/emp/type-model/deleteById/${id}`);
    },

    // 新增客户信息
    saveCustomerCompanyInfo(params) {
        return postJson("/baseinfo/company/save", params);
    },

    // 删除单个客户信息
    deleteCustomerCompanyInfo(id) {
        return get(`/baseinfo/company/delete/${id}`);
    },

    // 获取单个客户信息
    getCustomerCompanyInfo(id) {
        return get(`/baseinfo/company/info/${id}`);
    },

    // 更新客户信息
    updateEditCustomerCompanyInfo(params) {
        return postJson(`/baseinfo/company/update`, params);
    },

    // 获取客户信息合作情况
    findCooperateInfo(id) {
        return get(`/baseinfo/customer-company-info/findCooperateInfo/${id}`);
    },

    // 查询企业人员
    getCompanyUserList(params, menuId) {
        return postJson(`/baseinfo/company-link-info/table/${menuId}`, params);
    },

    // 查询企业人员下拉
    getCompanyPersonList(params) {
        return get(`/baseinfo/company-link-info/getSelectList`, params);
    },

    // 新增企业人员
    saveCompanyUser(params) {
        return postJson(`/baseinfo/company-link-info/save`, params);
    },

    // 更新企业人员
    updateCompanyUser(params) {
        return postJson(`/baseinfo/company-link-info/update`, params);
    },

    // 删除企业人员
    deleteCompanyUser(id) {
        return get(`/baseinfo/company-link-info/delete/${id}`);
    },

    // 获取项目记录
    getTableByCompany(params) {
        return postJson(`/project/project/tableByCompany`,params);
    },

    // 获取合作情况
    getCountCooperateInfo(id) {
        return get(`/baseinfo/company/countCooperateInfo/${id}`);
    },

    // 获取项目转换率
    getCountConverse(id) {
        return get(`/baseinfo/company/countConverse/${id}`);
    },

    //查询变更记录
    getChangeRecord(params) {
        return postJson(`/baseinfo/company-change-log/table`,params);
    },
};

export default baseManageApi;
