import { render, staticRenderFns } from "./inputBill.vue?vue&type=template&id=31a6de99&scoped=true&"
import script from "./inputBill.vue?vue&type=script&lang=js&"
export * from "./inputBill.vue?vue&type=script&lang=js&"
import style0 from "./inputBill.vue?vue&type=style&index=0&id=31a6de99&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31a6de99",
  null
  
)

export default component.exports