// 编辑进项发票 // popEditInputBill
<template>
    <div class="popEditInputBill">
        <el-dialog
            :title="`${id ? '编辑' : '新增'}${routeName}`"
            :close-on-click-modal="false"
            :visible.sync="dialogVisible"
            :modal-append-to-body="true"
            custom-class="popEditInputBillDialog"
            :append-to-body="true"
            width="1000px"
            :before-close="close"
        >
            <el-form
                :model="form"
                ref="form"
                :rules="formRules"
                label-position="top"
                :inline="false"
            >
                <div
                    class="info"
                    v-if="routeName === '销项发票'"
                    style="padding: 0 0 30px"
                >
                    <div class="field">
                        客户/供应商：{{
                            form.companyName || "无"
                        }}
                        &nbsp;&nbsp;|&nbsp;&nbsp;待开票余额：{{
                            form.invoiceBalance || 0
                        }}
                    </div>
                </div>

                <el-row :gutter="60" v-if="routeName === '进项发票'">
                    <el-col :span="8">
                        <el-form-item label="往来单位" prop="companyId">
                            <cust-sup-choose
                                ref="custSupChoose"
                                placeholderStr="请选择往来单位"
                                :showSeeProvider="false"
                                :disable="true"
                                :shouldClean="true"
                                :isScorll="true"
                                :chooseTypeArr="['PROVIDER', 'PERSON']"
                                :backShow="
                                    form.companyId
                                        ? {
                                              value: form.companyId,
                                              label: form.companyName,
                                          }
                                        : null
                                "
                                @handClear="handClear"
                                :cleanProvider="cleanProvider"
                                @treeSelectNodeClick="selectTreeCustClick"
                            ></cust-sup-choose>
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 支出单据-表格 -->
                <div
                    class="formTable"
                    v-if="routeName === '进项发票' && form.editFlag"
                >
                    <div class="label"><i class="red">*</i> 支出单据</div>
                    <div class="tableBox">
                        <el-table
                            :data="form.invoiceInputReceiptSaveActionList"
                            border
                            stripe
                        >
                            <el-table-column
                                prop="dateStr"
                                label="日期"
                                width="128"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="incomeCode"
                                label="编号"
                                width="160"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="projectName"
                                label="项目"
                                min-width="128"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="businessManName"
                                label="业务员"
                                width="128"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="deptName"
                                label="部门"
                                width="128"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="remark"
                                label="备注"
                                min-width="128"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="invoiceAmount"
                                label="应开票金额（元）"
                                min-width="128"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="canApplyAmount"
                                label="可申请金额（元）"
                                min-width="128"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="applicantAmount"
                                label="本次申请"
                                fixed="right"
                                width="150"
                            >
                                <div slot="header">
                                    <i class="red">*</i>&nbsp;&nbsp;本次申请
                                </div>
                                <template slot-scope="scope">
                                    <el-input-money
                                        v-model="scope.row.applicantAmount"
                                        placeholder="请输入本次申请"
                                    ></el-input-money>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="expendId"
                                label="操作"
                                align="center"
                                fixed="right"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <el-popconfirm
                                        confirm-button-text="删除"
                                        cancel-button-text="取消"
                                        icon="el-icon-info"
                                        icon-color="red"
                                        @confirm="delBill(scope.$index)"
                                        title="真的要删除吗？"
                                    >
                                        <el-link type="danger" slot="reference"
                                            >删除</el-link
                                        >
                                    </el-popconfirm>
                                </template>
                            </el-table-column>
                        </el-table>
                        <template v-if="form.companyId">
                            <div
                                class="tableAddBtnBar"
                                v-if="companyType === 'PERSON'"
                                @click="openpopSelectBillTicket"
                            >
                                <i class="el-icon-plus"></i>选择费用单据
                            </div>
                            <div
                                class="tableAddBtnBar"
                                v-else
                                @click="openpopSelectTicket"
                            >
                                <i class="el-icon-plus"></i>选择支出单据
                            </div>
                        </template>
                    </div>
                </div>

                <!-- 收入单据-表格 -->
                <div class="formTable" v-if="routeName === '销项发票'">
                    <div class="label"><i class="red">*</i> 收入单据</div>
                    <div class="tableBox">
                        <el-table
                            :data="form.invoiceReceiptsSaveActionList"
                            border
                            stripe
                        >
                            <el-table-column
                                prop="incomeDateStr"
                                label="日期"
                                width="128"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="incomeCode"
                                label="编号"
                                width="160"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="incomeAmount"
                                label="收入金额（元）"
                                min-width="128"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="applicantBalance"
                                label="开票余额（元）"
                                width="128"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="applicantAmount"
                                label="申请开票金额（元）"
                                width="128"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="completeAmount"
                                label="已开票金额（元）"
                                min-width="128"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="currentInvoicingAmount"
                                label="本次开票（元）"
                                min-width="128"
                            >
                            </el-table-column>
                        </el-table>
                    </div>
                </div>

                <el-row :gutter="60">
                    <el-col :span="8">
                        <el-form-item label="发票类型" prop="type">
                            <el-select
                                v-model="form.type"
                                placeholder="请选择发票类型"
                            >
                                <el-option
                                    v-for="item in billTypeOpts"
                                    :key="item.code"
                                    :label="item.label"
                                    :value="item.code"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="销方公司" prop="sellerCompanyId">
                            <!-- <template v-if="form.editFlag">
                                <cust-sup-choose placeholderStr="请选择销方公司"
                                                 :showSeeProvider="false"
                                                 :disable="true"
                                                 :shouldClean="true"
                                                 :isScorll="true"
                                                 @handClear="clearProvider"
                                                 :cleanProvider="cleanProvider"
                                                 :backShow="{value: form.sellerCompanyId, label: form.sellerCompanyName}"
                                                 @treeSelectNodeClick="customerClick"></cust-sup-choose> -->
                            <!-- </template>
                            <template v-else> -->
                            <el-input
                                v-model="form.sellerCompanyName"
                                placeholder="请输入销方公司"
                                clearable
                            ></el-input>
                            <!-- </template> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="税号" prop="taxId">
                            <el-input
                                v-model="form.taxId"
                                placeholder="请输入税号"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开票日期" prop="occurDate">
                            <el-date-picker
                                v-model="form.occurDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                format="yyyy年MM月dd日"
                                placeholder="选择开票日期"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="发票号码" prop="invoiceNumber">
                            <el-input
                                v-model="form.invoiceNumber"
                                placeholder="请输入发票号码"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="routeName === '进项发票'">
                        <el-form-item label="内容" prop="content">
                            <el-input
                                v-model="form.content"
                                placeholder="请输入内容"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <template v-if="routeName === '销项发票'">
                        <el-col :span="8">
                            <el-form-item label="发票抬头" prop="invoiceTitle">
                                <el-input
                                    v-model="form.invoiceTitle"
                                    placeholder="请输入发票抬头"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="银行账号" prop="account">
                                <!-- 选择 供应商/客户 账户 -->
                                <com-select-supplier-account
                                    v-model="form.accountObj"
                                    :selectCusId="form.companyId"
                                    placeholder="请选择收款账户"
                                    @change="selectAccount"
                                    returnType="Object"
                                ></com-select-supplier-account>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="开户行" prop="accountBank">
                                <el-input
                                    v-model="form.accountBank"
                                    placeholder="请输入开户行"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="联系电话" prop="phone">
                                <el-input
                                    v-model="form.phone"
                                    placeholder="请输入联系电话"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="地址" prop="addr">
                                <el-input
                                    v-model="form.addr"
                                    placeholder="请输入地址"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </template>
                </el-row>

                <!-- 收票明细 | 开票明细-表格 -->
                <div class="formTable">
                    <div class="label">
                        <i class="red">*</i>
                        {{ routeName === "销项发票" ? "开票" : "收票" }}明细
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <template v-if="routeName === '进项发票'">
                            <el-radio-group
                                v-model="form.taxIncluded"
                                @change="calcAmount"
                            >
                                <el-radio :label="true">含税</el-radio>
                                <el-radio :label="false">不含税</el-radio>
                            </el-radio-group>
                        </template>
                    </div>
                    <div class="tableBox">
                        <el-table
                            :data="form.invoiceDetailSaveActionList"
                            border
                            stripe
                        >
                            <el-table-column
                                prop="content"
                                label="内容"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <el-input
                                        v-model="scope.row.content"
                                        placeholder="请输入内容"
                                    ></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="amount"
                                label="金额（元）"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <el-input-money
                                        v-model="scope.row.amount"
                                        placeholder="请输入金额"
                                    ></el-input-money>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="taxRate"
                                label="税率（%）"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <el-input-number
                                        v-model="scope.row.taxRate"
                                        :max="100"
                                        :min="0"
                                        :controls="false"
                                        style="width: 100%"
                                        placeholder="请输入税率"
                                    ></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="taxAmount"
                                label="税额（元）"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <el-input
                                        v-model="scope.row.taxAmount"
                                        disabled
                                        placeholder="请输入税额"
                                    ></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id" label="操作" width="60">
                                <template slot-scope="scope">
                                    <el-popconfirm
                                        confirm-button-text="删除"
                                        cancel-button-text="取消"
                                        icon="el-icon-info"
                                        icon-color="red"
                                        @confirm="del(scope.$index)"
                                        title="真的要删除吗？"
                                    >
                                        <el-link type="danger" slot="reference"
                                            >删除</el-link
                                        >
                                    </el-popconfirm>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="tableAddBtnBar" @click="addDetailsRow">
                            <i class="el-icon-plus"></i>添加明细
                        </div>
                    </div>
                </div>
                <div class="countBox" v-if="routeName === '进项发票'">
                    <div class="countItem">
                        <div class="count">{{ form.totalAmount || 0.0 }}</div>
                        <div class="unit">价税合计（元）</div>
                        <div class="line"></div>
                    </div>
                </div>

                <el-row :gutter="60">
                    <el-col :span="24">
                        <el-form-item label="备注" prop="remark">
                            <el-input
                                v-model="form.remark"
                                type="textarea"
                                rows="5"
                                placeholder="请输入备注"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="popItemBox" v-if="form.id">
                    <div class="title">附件</div>
                    <div class="p20">
                        <com-file-upload-list
                            :showUpBtn="true"
                            :uploadData="{
                                resourceId: form.id,
                                resourceType: 'addInvoiceInput',
                            }"
                            :neddLoadFile="true"
                            :justShowFistOne="false"
                            :isNeedPopTemDownload="false"
                            :showDownloadBtn="true"
                            :showDelBtn="true"
                        ></com-file-upload-list>
                    </div>
                </div>

                <div class="footer">
                    <el-button type="danger" @click="close">取消</el-button>
                    <el-button type="primary" @click="save()">确定</el-button>
                </div>
            </el-form>
        </el-dialog>

        <!-- 选择单据 -->
        <pop-select-ticket
            v-if="popSelectTicketVisible"
            incomeOrPay="pay"
            dialogTitle="支出单据"
            :companyId="form.companyId"
            @checkTicket="handleCheckTicket"
            @close="closepopSelectTicket"
        ></pop-select-ticket>
        <!-- 选择费用单据 -->
        <pop-select-bill-ticket
            v-if="popSelectBillTicketVisible"
            :companyId="form.companyId"
            @checkTicket="handleCheckTicket"
            @close="closepopSelectBillTicket"
        ></pop-select-bill-ticket>
    </div>
</template>

<script>
import PopSelectBillTicket from "./popSelectBillTicket";
import ComSelectSupplierAccount from "../../../modleComponents/account/comSelectSupplierAccount";
import PopSelectTicket from "./popSelectTicket";
import CustSupChoose from "../../../components/selectTree/custSupChoose";
import ElInputMoney from "../../../components/form/elInputMoney";
import ComFileUploadList from "../../../components/upload/comFileUploadList";
import { Loading } from "element-ui";
import API from "@/api/finance.js";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "popEditInputBill",

    props: {
        // 查询用id
        id: {
            type: String,
        },
    },

    components: {
        PopSelectBillTicket,
        ComSelectSupplierAccount,
        PopSelectTicket,
        CustSupChoose,
        ElInputMoney,
        ComFileUploadList,
    },

    data() {
        return {
            dialogVisible: true,

            form: {
                companyId: "", // 往来单位
                type: "", // 发票类型
                sellerCompanyId: "", // 销方公司
                taxId: "", // 税号
                occurDate: "", // 开票日期
                invoiceNumber: "", // 发票号码
                content: "", // 内容
                remark: "", // 备注
                taxIncluded: true, // 是否含税
                invoiceInputReceiptSaveActionList: [], // 支出单据
                invoiceDetailSaveActionList: [], // 收票明细
                editFlag: true, // 新增必有支出单据
            },
            formRules: {
                companyId: {
                    required: true,
                    message: "请验证往来单位",
                    trigger: "blur",
                }, // 往来单位
                type: {
                    required: true,
                    message: "请验证发票类型",
                    trigger: "blur",
                }, // 发票类型
                occurDate: {
                    required: true,
                    message: "请验证开票日期",
                    trigger: "blur",
                }, // 开票日期
                invoiceNumber: {
                    required: true,
                    message: "请验证发票号码",
                    trigger: "blur",
                }, // 发票号码
            },
            // 发票类型
            billTypeOpts: this.commonJs.getSelectByType(
                "InvoiceType",
                "billTypeOpts",
                this,
                true
            ),
            detailsInfo: {}, // 详情信息
            editRowIndex: null, // 正在编辑的行
            popSelectTicketVisible: false, // 选择单据弹窗显示
            popSelectBillTicketVisible: false, // 选择费用单据弹窗显示
        };
    },

    created() {},

    mounted() {
        if (this.id) {
            this.form.id = this.id;
            // 获取详情
            this.getInputBill();
        } else {
            // 获取新增的Id
            this.getId();
            this.form.issue = this.$moment().format("yyyy-MM");
        }
    },

    methods: {
        // 获取详情
        getInputBill() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API[this.routeName === "进项发票" ? "getInputBill" : "getOutBill"](
                this.id
            )
                .then((res) => {
                    loading.close();
                    this.form = Object.assign({}, res.content, {
                        invoiceInputReceiptSaveActionList:
                            res.content.invoiceInputReceiptSaveActionList || [],
                        type: res.content.type.name,
                        typeName: res.content.type.text,
                        accountObj: {
                            cardId: res.content.account,
                            bankName: res.content.accountBank,
                        },
                    });
                    this.$refs.custSupChoose.searchForm.type = res.content
                        .isCompany
                        ? "PROVIDER"
                        : "PERSON";
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 保存缴税费
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.routeName === "进项发票" && this.form.editFlag) {
                        let list = this.form.invoiceInputReceiptSaveActionList.filter(
                            (item) => {
                                return !Number(item.applicantAmount);
                            }
                        );
                        if (
                            list.length > 0 ||
                            this.form.invoiceInputReceiptSaveActionList
                                .length === 0
                        ) {
                            this.$message({
                                message: "请验证支出单据中的必填项！",
                                type: "error",
                            });
                            return;
                        }
                    }
                    let loading = Loading.service({
                        target: document.querySelector(".el-dialog"),
                    });
                    let data = Object.assign({}, this.form);
                    API[
                        this.routeName === "进项发票"
                            ? "saveInputBill"
                            : "saveOutputBill"
                    ](data)
                        .then(() => {
                            loading.close();
                            this.$message({
                                message: "操作成功！",
                                type: "success",
                            });
                            this.$emit("update");
                            this.close();
                        })
                        .catch(() => {
                            loading.close();
                        });
                }
            });
        },
        // 获取新增项目的Id
        getId() {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            commonAPI
                .getId()
                .then((res) => {
                    loading.close();
                    this.form.id = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 选择账户
        selectAccount() {
            if (this.form.accountObj) {
                let data = Object.assign({}, this.form, {
                    account: this.form.accountObj.cardId,
                    accountBank: this.form.accountObj.bankName,
                });
                this.$set(this, "form", data);
            } else {
                this.form.account = null;
                this.form.accountBank = null;
            }
        },
        // 添加明细
        addDetailsRow() {
            this.form.invoiceDetailSaveActionList.push({});
        },
        // 删除缴税费明细
        del(index) {
            this.form.invoiceDetailSaveActionList.splice(index, 1);
        },
        // 客户的选择
        customerClick(node) {
            this.form.sellerCompanyId = node.id;
        },
        // 客户的清空
        clearProvider() {
            this.form.sellerCompanyId = "";
        },
        // 选择单据 打开弹框
        openpopSelectTicket() {
            this.popSelectTicketVisible = true;
        },
        // 选择单据 关闭弹框
        closepopSelectTicket() {
            this.popSelectTicketVisible = false;
        },
        // 选择费用单据 打开弹框
        openpopSelectBillTicket() {
            this.popSelectBillTicketVisible = true;
        },
        // 选择费用单据 关闭弹框
        closepopSelectBillTicket() {
            this.popSelectBillTicketVisible = false;
        },
        // 选择单据 子组件返回数据
        handleCheckTicket(rows) {
            let data = rows.map((item) => {
                return {
                    // 后端保存所需
                    applicantAmount: item.canApplyAmount, // 本次申请和价税合计保持一致
                    expendId: item.expendId || null, // 收入单据id
                    incomeId: item.incomeId || null, // 收入单据id
                    incomeType: item.type, // 收入类型, 枚举 INCOME_CONTRACT(收入合同),OTHER_INCOME(其他收入)
                    // 前端页面需要
                    date: item.date, // 日期
                    dateStr: item.dateStr, // 日期
                    code: item.incomeCode, // 编号
                    incomeCode: item.incomeCode, // 编号
                    projectName: item.projectName, // 项目
                    businessManName: item.chargeUserName, // 业务员
                    deptName: item.deptName, // 部门
                    remark: item.remark, // 备注
                    invoiceAmount: item.invoiceAmount, // 应开票金额（元）
                    canApplyAmount: item.canApplyAmount, // 可申请金额（元）
                };
            });
            this.form.invoiceInputReceiptSaveActionList = this.form.invoiceInputReceiptSaveActionList.concat(
                data
            );
        },
        // 删除单据
        delBill(index) {
            this.form.invoiceInputReceiptSaveActionList.splice(index, 1);
        },
        // 计算价税合计
        calcAmount() {
            // 价税合计计算公式
            // 1、含税时：价税合计=金额
            // 2、不含税时：价税合计=金额+金额*税率
            let count = 0;
            this.form.invoiceDetailSaveActionList.forEach((item) => {
                if (this.form.taxIncluded) {
                    // 含税
                    count = this.commonJs.keepTwoDecimal(
                        count + Number(item.amount)
                    );
                    item.taxAmount =
                        this.commonJs.keepTwoDecimal(
                            (item.amount * (item.taxRate / 100)) /
                                (1 + item.taxRate / 100)
                        ) || 0;
                } else {
                    // 不含税
                    count = this.commonJs.keepTwoDecimal(
                        count +
                            Number(item.amount) +
                            Number(item.amount) * (item.taxRate / 100)
                    );
                    item.taxAmount =
                        this.commonJs.keepTwoDecimal(
                            item.amount * (item.taxRate / 100)
                        ) || 0;
                }
            });
            this.$set(this.form, "totalAmount", count);
            this.$set(
                this.form,
                "invoiceDetailSaveActionList",
                this.form.invoiceDetailSaveActionList
            );
        },
        // 客户的选择
        selectTreeCustClick(node) {
            this.form.companyId = node.id;
            this.form.companyName = node.name;
            this.form.invoiceInputReceiptSaveActionList = [];
            this.form.taxId = node.billNo; // 税号
            this.form.sellerCompanyName = node.longName; // 销方公司
        },
        // 客户的清空
        handClear() {
            this.form.companyId = "";
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {
        /**
         * 根据路由判断页面
         * outputBill == 销项发票, inputBill == 进项发票
         */
        routeName() {
            let routeInfo = {
                outputBill: "销项发票",
                inputBill: "进项发票",
            };
            return routeInfo[this.$route.name] || null;
        },
        /**
         * 往来单位是员工还是供应商
         */
        companyType() {
            return (
                this.$refs.custSupChoose &&
                this.$refs.custSupChoose.searchForm.type
            );
        },
    },

    watch: {
        // 收票明细
        "form.invoiceDetailSaveActionList": {
            deep: true,
            immediate: true,
            handler: function() {
                this.calcAmount();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.baseInfo {
    font-size: 16px;
    padding: 20px 0 40px;
}
</style>
