// 动态表格
<template>
    <div class="comDynamicTable">
        <el-table :data="tableData" border stripe>
            <!-- 只有其他收支显示此列 -->
            <template v-if="['收入明细', '支出明细'].indexOf(this.type) !== -1">
                <el-table-column
                    :label="
                        `${this.type === '收入明细' ? '收入类别' : '支出类别'}`
                    "
                    width="230"
                >
                    <template slot-scope="scope">
                        {{ scope.row.categoryTopPName }}-{{
                            scope.row.category
                        }}
                    </template>
                </el-table-column>
            </template>
            <!-- TODO 业务单号需要可以点击 打开出纳付款弹窗  -->
            <el-table-column
                v-for="col in tableColumns"
                :prop="col.id"
                :key="col.id"
                :label="col.label"
                align="left"
                :min-width="col.width"
            >
                <template slot-scope="scope">
                    <el-link
                        type="primary"
                        v-if="col.needClick"
                        @click="details(scope.row)"
                        >{{ scope.row[col.id] }}</el-link
                    >
                    <span v-else>{{ scope.row[col.id] || "暂无" }}</span>
                </template>
            </el-table-column>
        </el-table>

        <!-- 收款记录详情 -->
        <pop-pay-history
            v-if="popPayHistoryVisible"
            :incomeOrPay="this.type.indexOf('付款') !== -1 ? 'pay' : 'income'"
            :id="detailsRow.id"
            @close="closepopPayHistory"
        ></pop-pay-history>
        <!-- 其他收入|其他支出详情 -->
        <pop-other-pay-details
            v-if="popOtherPayDetailsVisible"
            :id="detailsId"
            @update="getList"
            @close="closepopOtherPayDetails"
        ></pop-other-pay-details>
    </div>
</template>

<script>
import PopOtherPayDetails from "./popOtherPayDetails";
import PopPayHistory from "../../moneyManage/components/popPayHistory";
export default {
    name: "comDynamicTable",

    props: {
        // 表格数据
        tableData: {
            type: Array,
            default() {
                return [];
            },
        },
        // 表格类型
        type: {
            type: String,
            default: "付款记录",
        },
        // 收款还是付款
        incomeOrPayCN: {
            type: String,
            default: "付",
        },
    },

    components: {
        PopOtherPayDetails,
        PopPayHistory,
    },

    data() {
        return {
            detailsRow: null, // 详情行数据
            popPayHistoryVisible: false, // 收款记录详情弹窗显示
            popOtherPayDetailsVisible: false, // 查看其他收入详情
        };
    },

    created() {},

    mounted() {},

    methods: {
        /* 查看业务单号详情 */
        details(data) {
            if (this.type === "付款记录" || this.type === "收款记录") {
                this.openpopPayHistory(data);
            } else if (data.incomeType) {
                if (
                    data.incomeType === "INCOME_CONTRACT" ||
                    data.incomeType === "PAY_CONTRACT"
                ) {
                    // 跳转到合同页面
                    let routeUrl = this.$router.resolve({
                        path: "/incomeManage/contractDetail",
                        query: { id: data.incomeId },
                    });
                    window.open(routeUrl.href, "_blank");
                } else {
                    // 打开其他收支弹窗
                    this.openpopOtherPayDetails(data);
                }
            }
        },
        // 收款记录详情 打开弹框
        openpopPayHistory(item) {
            this.detailsRow = item || null;
            this.popPayHistoryVisible = true;
        },
        // 收款记录详情 关闭弹框
        closepopPayHistory() {
            this.detailsRow = null;
            this.popPayHistoryVisible = false;
        },
        // 其他收入详情 打开弹框
        openpopOtherPayDetails(row) {
            this.detailsId = row.incomeId;
            this.popOtherPayDetailsVisible = true;
        },
        // 其他收入详情 关闭弹框
        closepopOtherPayDetails() {
            this.popOtherPayDetailsVisible = false;
        },
    },

    computed: {
        // 表格列数据
        tableColumns() {
            let cols = [];
            if (this.type === "付款记录" || this.type === "收款记录") {
                cols = [
                    { id: "payDate", label: "日期", width: "155" },
                    {
                        id: "code",
                        label: "业务单号",
                        width: "155",
                        needClick: true,
                    },
                    { id: "amount", label: "金额（元）", width: "155" },
                    { id: "remark", label: "备注", width: "155" },
                ];
            } else if (this.type === "相关单据") {
                cols = [
                    { id: "date", label: "日期", width: "155" },
                    {
                        id: "code",
                        label: `预${this.incomeOrPayCN}款编号`,
                        width: "155",
                        needClick: true,
                    },
                    { id: "amount", label: "金额（元）", width: "155" },
                    { id: "balance", label: "未冲销金额（元）", width: "155" },
                    { id: "remark", label: "备注", width: "155" },
                ];
            } else if (this.type === "收入明细" || this.type === "支出明细") {
                cols = [
                    { id: "taxRate", label: "税率（%）", width: "155" },
                    { id: "actualAmount", label: "金额（元）", width: "155" },
                    { id: "remark", label: "备注", width: "155" },
                ];
            } else if (this.type === "相关业务") {
                cols = [
                    { id: "dateStr", label: "日期", width: "128" },
                    {
                        id: "code",
                        label: "业务单号",
                        width: "160",
                        needClick: true,
                    },
                    {
                        id: "revenueAmount",
                        label: "收入金额（元）",
                        width: "155",
                    },
                    {
                        id: "applicantAmount",
                        label: "本次开票（元）",
                        width: "155",
                    },
                ];
            } else if (this.type === "销项发票相关业务") {
                cols = [
                    { id: "incomeDateStr", label: "日期", width: "128" },
                    {
                        id: "incomeCode",
                        label: "业务单号",
                        width: "160",
                        needClick: true,
                    },
                    {
                        id: "incomeAmount",
                        label: "收入金额（元）",
                        width: "155",
                    },
                    {
                        id: "currentInvoicingAmount",
                        label: "本次开票（元）",
                        width: "155",
                    },
                ];
            } else if (this.type === "开票记录") {
                cols = [
                    { id: "dateStr", label: "日期", width: "128" },
                    {
                        id: "invoiceTitle",
                        label: "抬头",
                        width: "160",
                        needClick: true,
                    },
                    { id: "invoiceNumber", label: "发票号码", width: "155" },
                    { id: "amount", label: "开票金额（元）", width: "155" },
                ];
            } else if (this.type === "收票记录") {
                cols = [
                    { id: "occurDateStr", label: "日期", width: "128" },
                    { id: "invoiceNumber", label: "发票号码", width: "155" },
                    {
                        id: "sellerCompanyName",
                        label: "销方公司",
                        width: "155",
                    },
                    { id: "amount", label: "金额（元）", width: "155" },
                    { id: "remark", label: "备注", width: "155" },
                ];
            } else if (this.type === "其他收支开票记录") {
                cols = [
                    { id: "dateStr", label: "日期", width: "128" },
                    { id: "invoiceNumber", label: "发票号码", width: "155" },
                    { id: "invoiceTitle", label: "抬头", width: "155" },
                    { id: "amount", label: "金额（元）", width: "155" },
                    { id: "remark", label: "备注", width: "155" },
                ];
            } else if (this.type === "其他收支收票记录已申请") {
                cols = [
                    { id: "dateStr", label: "日期", width: "128" },
                    { id: "code", label: "申请单号", width: "155" },
                    { id: "invoiceTitle", label: "抬头", width: "155" },
                    {
                        id: "applicantAmount",
                        label: "申请金额（元）",
                        width: "155",
                    },
                    {
                        id: "completeAmount",
                        label: "已开金额（元）",
                        width: "155",
                    },
                    { id: "remark", label: "备注", width: "155" },
                ];
            } else if (this.type === "其他收支收票记录") {
                cols = [
                    { id: "occurDateStr", label: "日期", width: "128" },
                    { id: "invoiceNumber", label: "发票号码", width: "155" },
                    {
                        id: "sellerCompanyName",
                        label: "销方公司",
                        width: "155",
                    },
                    { id: "amount", label: "金额（元）", width: "155" },
                    { id: "remark", label: "备注", width: "155" },
                ];
            }
            return cols;
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
// .comDynamicTable{

// }
</style>
