// 选择费用单据
<template>
    <el-dialog :close-on-click-modal="false"
               :title="dialogTitle"
               :visible="true"
               width="1200px"
               :before-close="close">

        <div class="popItemBox">

            <el-tabs type="border-card"
                     v-model="currentTabs"
                     :before-leave="handleTip">
                <el-tab-pane label="费用单据"
                             name="费用单据">
                </el-tab-pane>
            </el-tabs>

            <el-form :model="form"
                     ref="form"
                     :rules="rules"
                     :inline="false"
                     size="normal">
                <div class="searchBar ofh">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             class="fll"
                             :inline="true">
                        <el-form-item label="报销编号"
                                      prop="code">
                            <el-input v-model="searchForm.code"
                                      placeholder="请输入报销编号"
                                      clearable />
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary"
                                       @click="getList()">搜索</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="resetSearchForm">清除</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="tableBox">
                    <el-table :data="tableData"
                              ref="tableBox"
                              height="300px"
                              stripe>
                        <el-table-column type="selection"
                                         width="55">
                        </el-table-column>
                        <el-table-column prop="code"
                                         label="报销编号"
                                         min-width="auto"
                                         width="160">
                        </el-table-column>
                        <el-table-column prop="expenseType"
                                         label="费用类型"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="projectName"
                                         label="项目"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="amount"
                                         label="金额（元）"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="deptName"
                                         label="部门"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                    </el-table>
                </div>
                <el-form-item class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="onSubmit">确认</el-button>
                </el-form-item>
            </el-form>

        </div>

    </el-dialog>
</template>
<script>
import API from "@/api/finance.js";
export default {
    name: "popConnectProject",
    components: {},
    props: {
        // 弹窗标题
        dialogTitle: {
            type: String,
            default: "关联项目",
        },
        // 根据companyId查询单据
        companyId: {
            type: String,
        },
    },
    data() {
        return {
            currentTabs: "费用单据",
            form: {},
            rules: {},
            searchForm: {},
            isloading: false,
            rowData: null,
            tableData: [],
        };
    },
    mounted() {},
    methods: {
        /* 获取列表 */
        getList() {
            this.isloading = true;
            this.$refs.tableBox && this.$refs.tableBox.clearSelection();
            // 其他收支
            let data = {
                code: this.searchForm.code,
                occurUserId: this.companyId,
            };
            API.getBillTicketLis(data)
                .then((res) => {
                    this.isloading = false;
                    this.$set(this, "tableData", res.content);
                    this.$refs.tableBox.doLayout();
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //清空筛选条件
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 查询列表
                    this.getList();
                }
            });
        },

        // 提交表单
        onSubmit() {
            // 多选
            let rows = this.$refs.tableBox.store.states.selection;
            this.$emit("checkTicket", rows);
            this.close();
        },

        // 提示
        handleTip() {
            if (
                this.$refs.tableBox &&
                this.$refs.tableBox.store.states.selection.length
            ) {
                return new Promise((res, rej) => {
                    this.$confirm(
                        "你已有选中的数据，现在切换标签将会清空你选中的数据，是否继续?",
                        "提示",
                        {
                            confirmButtonText: "是",
                            cancelButtonText: "取消",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            res();
                        })
                        .catch(() => {
                            rej();
                        });
                });
            }
        },

        close() {
            this.$emit("close");
        },
    },
    computed: {},
    watch: {
        currentTabs: {
            immediate: true,
            handler: function () {
                this.getList();
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.el-form {
    padding: 0;
}
.searchBar {
    padding: 0 20px;
}
.tableBox {
    padding: 0 20px;
}
</style>