//特殊树  筛选供应商/客户下拉树
<template>
    <div :class="{'hidden':hidden,
    'isScorll': isScorll}"
         class="el-row">
        <el-select v-model="searchForm.value"
                   ref="selectProjectCust"
                   :disabled="disabled"
                   @visible-change="showSelectLis"
                   :clearable="shouldClean"
                   filterable
                   :filter-method="filterNode"
                   @clear="handClear"
                   :placeholder="placeholderStr">

            <el-option class="selectProjectCust"
                       :value="searchForm.value"
                       :label="searchForm.label"
                       style="width: 560px;height:auto;overflow: auto;background-color:#fff">
                <el-row class="supChooseContainer"
                        @click.stop>
                    <el-form-item label="类别:"
                                  style="margin-top:20px">
                        <div @click.stop>
                            <el-radio-group v-model="searchForm.type">
                                <el-radio label="CUSTOMER"
                                          v-if="chooseTypeArr.indexOf('CUSTOMER') !== -1"
                                          :disabled="showCustOrProvide ? showCustOrProvide === 'CUSTOMER' ? false : true : false ">客户</el-radio>
                                <el-radio label="PROVIDER"
                                          v-if="chooseTypeArr.indexOf('PROVIDER') !== -1"
                                          :disabled="showCustOrProvide ? showCustOrProvide === 'PROVIDER' ? false : true : false ">供应商</el-radio>
                                <el-radio label="PERSON"
                                          v-if="chooseTypeArr.indexOf('PERSON') !== -1">员工</el-radio>
                            </el-radio-group>
                        </div>

                    </el-form-item>
                    <div class="treeBox_"
                         v-loading="isloading">
                        <!-- 选择供应商或客户 -->
                        <el-tree v-if="searchForm.type !== 'PERSON'"
                                 class="filter-tree"
                                 :data="treeData"
                                 :props="defaultProps"
                                 node-key="id"
                                 :filter-node-method="filterTree"
                                 @node-click="handleNodeClick"
                                 :default-expand-all="false"
                                 :expandOnClickNode="false"
                                 ref="tree">
                        </el-tree>
                        <!-- 选择员工 -->
                        <div v-else>
                            <el-cascader-panel :value="searchForm.personId"
                                               ref="selectPerson"
                                               :placeholder="placeholder || '请选择处理人员'"
                                               :show-all-levels="false"
                                               :options="options"
                                               @change="changePerson($event)"
                                               :props="{ emitPath: false,
                                                        label:'label',
                                                        expandTrigger: 'hover',
                                                        multiple: multiple,
                                                        value: 'id' }"
                                               clearable></el-cascader-panel>
                        </div>
                    </div>

                    <!-- <el-form-item style="margin-top:20px;text-align:right">
                        <div
                                   @click="ok">确认</div>
                    </el-form-item> -->
                </el-row>

            </el-option>
        </el-select>
        <!-- <el-button type="primary"
                   v-if="showSeeProvider"
                   @click="seeProvide"
                   :disabled="searchForm.value ? false : true"
                   style="margin-left:20px">查看</el-button> -->
    </div>
</template>

<script>
import baseManageApi from "@/api/baseManageApi.js";
import selectOptsAPI from "@/api/selectOpts.js";
export default {
    components: {},
    name: "custSupChoose",
    props: {
        // searchForm: {
        //     type: Object,
        //     default() {
        //         return {
        //             label: "",
        //             value: "",
        //             type: "CUSTOMER",
        //         };
        //     },
        // },
        defaultProps: {
            type: Object,
            default() {
                return {
                    children: "children",
                    label: "label",
                };
            },
        },
        placeholderStr: {
            type: String,
            default: "请选择",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hidden: {
            type: Boolean,
            default: false,
        },

        //回显
        backShow: {
            type: Object,
        },

        //是否显示查看供应商
        showSeeProvider: {
            type: Boolean,
            default: true,
        },
        // 是否可以选择员工
        chooseTypeArr: {
            type: Array,
            default() {
                return ["CUSTOMER", "PROVIDER"];
            },
        },

        //是否单独显示客户或者供应商  没得就是显示所有
        showCustOrProvide: {
            type: String,
            default: "",
        },

        //是否需要清空数据
        cleanProvider: {
            default: false,
        },

        // 是否需要清空按钮  只能用于单选  多选不适用
        shouldClean: {
            type: Boolean,
            default: false,
        },
        // 是否需要滚动条
        isScorll: {
            type: Boolean,
            default: false,
        },

        // 对数进行筛选
        filterText: {
            type: String,
            default: "",
        },
    },

    watch: {
        //显示客户或者供应商
        showCustOrProvide: {
            deep: true,
            immediate: true,
            handler: function (val) {
                val && (this.searchForm.type = val);
                val && this.getOrgList(val);
            },
        },
        //类型切换  加载列表
        "searchForm.type": {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.getOrgList(val);
            },
        },
        //回显数据
        backShow: {
            deep: true,
            immediate: true,
            handler: function (val) {
                if (this.searchForm.type === "PERSON") {
                    this.searchForm.personId = val && val["value"];
                    this.searchForm.value = val && val["value"];
                    this.searchForm.label = val && val["label"];
                } else {
                    val &&
                        val["value"] &&
                        this.getCustomerCompanyInfo(val["value"]);
                }
            },
        },
        //清空筛选条件
        cleanProvider: {
            deep: true,
            immediate: true,
            handler: function (newval) {
                newval && this.cleanSelect();
            },
        },

        /* 树的筛选 */
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },
    data() {
        return {
            searchForm: {
                label: "",
                value: "",
                type: "",
                personId: "",
            },
            treeData: [],
            checkTree: null, //选中树的数据
            isloading: false,
        };
    },
    mounted() {
        this.searchForm.type = this.searchForm.type || this.chooseTypeArr[0];
        this.showCustOrProvide === "" ? this.getOrgList() : "";
    },
    computed: {},
    methods: {
        // // 筛选 树
        filterNode(value) {
            this.$refs.tree.filter(value || "");
        },

        /* 树的筛选方法 */
        filterTree(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },

        //获取客户/供应商下拉树
        getOrgList(type = this.searchForm.type) {
            if (type === "PERSON") {
                // 选择内部员工
                this.getPersonInDeptTree();
            } else {
                this.isloading = true;
                baseManageApi
                    .getCustomerCompanyTree({
                        type: type,
                    })
                    .then((res) => {
                        this.isloading = false;
                        this.treeData = res.content;
                        console.log(99999999);
                    })
                    .catch(() => {
                        this.isloading = false;
                    });
            }
        },
        // 选择人员
        changePerson() {
            let node = this.$refs.selectPerson.getCheckedNodes();
            if (node.length && node[0].data.type === "person") {
                this.searchForm.chooseNode = node[0].data;
                this.searchForm.label = node[0].data.label;
                this.searchForm.value = node[0].data.id;
                this.$emit("treeSelectNodeClick", node[0].data);
            }
        },
        //下拉树的选择
        handleNodeClick(node) {
            this.searchForm.chooseNode = node;
            this.searchForm.label = node.label;
            this.searchForm.value = node.label;
            this.$emit("treeSelectNodeClick", node);
            this.$refs.selectProjectCust.blur();
        },
        // 清空选择
        cleanSelect() {
            if (this.$refs.tree) {
                this.$refs.tree.setCheckedKeys([]); // 清除选中数组
                this.$refs.tree.setCurrentKey(null); // 清除高亮
            }
            this.searchForm.label = "";
            this.searchForm.value = "";
        },

        ok() {
            this.$refs.selectProjectCust.blur();
        },

        //查看供应商
        seeProvide() {
            // this.$router.push({
            //     path: "/baseInfoManage/supplierDetail",
            //     query: { deviceTypeInfo: JSON.stringify(this.searchForm) },
            // });
            let routeData = this.$router.resolve({
                path: "/baseInfoManage/supplierDetail",
                query: { deviceTypeInfo: JSON.stringify(this.searchForm) },
            });
            window.open(routeData.href, "_blank");
        },

        //清空选择项 事件回调
        handClear() {
            this.cleanSelect();
            this.$emit("handClear", true);
        },
        // 获取客户信息，用于回显
        getCustomerCompanyInfo(id) {
            if (this.searchForm.label && this.backShow.value === id) {
                return;
            } else {
                baseManageApi.getCustomerCompanyInfo(id).then((res) => {
                    let label = res.content.name;
                    label &&
                        (this.searchForm = Object.assign({}, this.searchForm, {
                            label,
                        }));
                });
            }
        },
        // 公司下的联系人列表
        getPersonInDeptTree() {
            this.isloading = true;
            return selectOptsAPI
                .getPersonInDeptTree()
                .then((res) => {
                    this.isloading = false;
                    this.options = res.content;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        // 阻止收起下拉
        stopClose(event) {
            event.preventDefault();
            this.$refs.selectProjectCust.blur();
        },
        // 下拉显示时触发刷新下拉
        showSelectLis() {
            this.filterNode();
        },
    },
};
</script>

<style scoped>
.pagination-container {
    background: #fff;
    margin-top: 20px;
    /* padding: 32px 16px; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pagination-container.hidden {
    display: none;
}
.treeBox_ {
    padding: 12px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;
    height: auto;
    overflow: auto;
}
.supChooseContainer {
    padding-bottom: 20px;
}
</style>
<style>
.el-select-dropdown__wrap {
    max-height: none !important;
}
</style>
