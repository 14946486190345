// 输入金额保留两位小数精度
<template>

    <div class="elInputMoney">
        <el-input v-model="value"
                  :controls="false"
                  :placeholder="placeholder"
                  label="label"
                  @input="inputMoney"
                  @change="change"
                  :disabled="disabled"
                  :clearable="clearable">
            <template slot="append"
                      v-if="unitText">{{unitText}}</template>
        </el-input>
    </div>

</template>

<script>
export default {
    name: "elInputMoney",
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        // v-model 双向绑定：接受父组件的值
        value: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "请输入金额",
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        // 是否可以清空
        clearable: {
            type: Boolean,
            default: true,
        },
        // 单位是元还是万元
        unit: {
            type: String,
            default: "元",
        },
        // 输入框后面跟单位
        unitText: {
            type: String,
            defalt: null,
        },
    },

    components: {},

    data() {
        return {
            loading: false,
        };
    },

    created() {},

    mounted() {
        // 输入金额
        this.inputMoney();
    },

    methods: {
        // 输入金额
        inputMoney() {
            if (this.value) {
                let value =
                    this.unit === "万元"
                        ? String(this.value).match(/^\d*(\.?\d{0,4})/g)[0] ||
                          0.0
                        : String(this.value).match(/^\d*(\.?\d{0,2})/g)[0] ||
                          0.0;
                // 判断小数点后面的值是否是指定位数
                let valuePer = value.split(".")[0];
                let valueAft = value.split(".")[1] || "00";
                valueAft =
                    this.unit === "万元" && valueAft.length !== 6
                        ? `${valueAft + "000000".slice(valueAft.length)}`
                        : valueAft;
                valueAft =
                    this.unit === "元" && valueAft.length !== 2
                        ? `${valueAft + "00".slice(valueAft.length)}`
                        : valueAft;
                this.value = `${valuePer}.${valueAft}`;
                this.$emit("input", value);
            } else {
                this.$emit("input", `0${this.moneySuffix}`);
            }
        },
        // 与父组件通信
        change() {
            let value = String(this.value) || `0${this.moneySuffix}`;
            if (value.indexOf(".") === -1) {
                value = value + this.moneySuffix;
            }
            this.value = value;
            this.$emit("input", value);
            this.$emit("change", value);
        },
    },

    computed: {
        // 小数点后的默认值，万元为".0000"，元为".00"
        moneySuffix() {
            let suffix = ".00";
            if (this.unit === "万元") {
                suffix = ".0000";
            }
            return suffix;
        },
    },

    watch: {
        // 接收到父组件的value
        value: {
            deep: true,
            immediate: true,
            handler: function () {
                // 回显数据
                // this.showData();
                // this.$emit("input", this.value);

                // 输入金额
                // this.inputMoney();
                this.$emit("input", this.value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
// .elInputMoney{

// }
</style>
